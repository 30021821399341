<template>
  <div class="product">
    <van-nav-bar
      title="产品列表"
      left-text="返回"
      left-arrow
      @click-left="toHome"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="true"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-search
        class="searchInp"
        v-model="searchValue"
        placeholder="请输入产品关键词"
        shape="round"
        @clear="clearValue"
        @input="inputValue"
        fixed
      />
      <van-cell-group
        class="mt10"
        v-for="(item, idx) in productList"
        :key="idx"
      >
        <van-cell>
          <template #title>
            <van-row type="flex" justify="space-between">
              <van-col span="12"
                ><h4 class="custom-title">{{ item.sample_code }}</h4></van-col
              >
              <van-col span="12"
                ><h4 class="custom-title">{{ item.sample_name }}</h4></van-col
              >
            </van-row>
            <van-row type="flex" justify="space-between">
              <van-col span="12"
                ><span class="custom-title"
                  >ERP编码：{{ item.sample_erp_code }}</span
                ></van-col
              >
              <van-col span="12"
                ><span class="custom-title"
                  >产品规格：{{ item.sample_specs }}</span
                ></van-col
              >
            </van-row>
            <!-- <van-row type="flex" justify="space-between">
              <van-col span="24"><span class="custom-title">备注：{{item.mark}}</span></van-col>             
            </van-row>             -->
            <van-row class="mt10" type="flex" justify="end">
              <span style="flex: 2">
                <span style="margin-right: 10px" v-if="item.sample_status == '0'">禁用产品</span>
                <span style="margin-right: 10px" v-if="item.sample_status == '1'">启用产品</span>
                <van-switch
                  v-model="item.sample_status"
                  active-value="0"
                  inactive-value="1"
                  size="20px"
                  style="vertical-align: top"
                  @change="changSwitch(item.sample_status, item.id)"
                >
                </van-switch>
              </span>
              <van-button
                plain
                hairline
                round
                type="info"
                size="mini"
                @click="detection(item)"
                >检测指标</van-button
              >
              <van-button
                plain
                hairline
                round
                type="info"
                size="mini"
                @click="toEdit(item.id)"
                >编 辑</van-button
              >
              <!-- <van-button
                plain
                hairline
                round
                type="danger"
                size="mini"
                @click="delBtn(item.id, item.sample_name)"
                >删 除</van-button
              > -->
              <!-- <van-switch
                v-model="item.sample_status"
                active-value="0"
                inactive-value="1"
                size="20px"
                @change="changSwitch(item.sample_status, item.id)"
              >
              </van-switch>             -->
            </van-row>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 编辑 -->
    <van-popup
      v-model="toEditshow"
      class="elasticlayer tac"
      :closeable="true"
      position="bottom"
      round
    >
      <h3 class="tac">{{ layerTitle }}</h3>
      <van-cell-group inset height="300px">
        <van-field
          v-model="sampleCode"
          input-align="right"
          label="样品编码"
          readonly
          placeholder="请输入样品编码"
        />
        <van-field
          v-model="erpCode"
          input-align="right"
          label="ERP编码"
          placeholder="请输入ERP编码"
        />
        <van-field
          v-model="sampleName"
          input-align="right"
          label="产品名称"
          placeholder="请输入产品名称"
        />
        <van-field
          v-model="sampleSpecs"
          input-align="right"
          label="产品规格"
          placeholder="请输入产品规格"
        />
        <van-field
          v-model="jxName"
          is-link
          readonly
          label="检测剂型"
          placeholder="请输入产品剂型"
          @click="showJx = true"
          input-align="right"
        />
        <van-field
          v-model="supplier"
          is-link
          readonly
          label="供应商"
          placeholder="选择供应商"
          @click="getSupplier"
          input-align="right"
        />
      </van-cell-group>
      <van-button
        class=""
        round
        block
        size="small"
        type="primary"
        @click="preservEdit"
        v-track="{
          triggerType: 'click',
          title: '编辑产品',
          currentUrl: $route.path,
          behavior: this._data,
          duration: new Date().getTime(),
        }"
        style="position: absolute; bottom: 30px"
        >保 存</van-button
      >
    </van-popup>
    <div class="import-excel" title="导入文件">
      <van-uploader
        v-model="fileList"
        accept=".xls, .xlsx"
        :after-read="afterRead"
        :deletable="false"
        multiple
        result-type="text"
        :preview-image="false"
      >
        <!-- <van-button icon="icon-font-daoru"></van-button> -->
        <van-icon
          class-prefix="icon-font"
          name="daoru"
          color="#fff"
          size="22"
          style="line-height: 48px"
        />
      </van-uploader>
    </div>
    <!-- 检测指标 弹窗 -->
    <van-popup
      v-model="checkShow"
      class="elasticlayer"
      :closeable="true"
      position="bottom"
      round
    >
      <h3 class="tac">{{ sampleName }}检测指标</h3>
      <van-tag
        class="add"
        type="primary"
        plain
        round
        size="medium"
        @click="addzb"
        ><van-icon name="plus" size="15px" color="#3d7cf3" />新增指标</van-tag
      >
      <!-- <van-action-sheet v-model="upShow" :actions="option1" @select="onSelect" class="elasticlayer" :overlay="false" /> -->
      <van-action-sheet
        v-model="upShow"
        title="检测指标"
        class="elasticlayer"
        :overlay="false"
      >
        <van-search
          v-model="zbValue"
          placeholder="请输入搜索关键词"
          @input="searchZb"
        />
        <van-action-sheet
          v-model="upShow"
          :actions="option1"
          @select="onSelect"
          :overlay="false"
          style="height: 45%"
        />
      </van-action-sheet>
      <!-- 新增下拉 -->
      <div style="height: 60%; overflow: scroll" class="selectAd">
        <van-cell-group v-for="item in checkList" :key="item.id">
          <van-field
            :label="item.check_name"
            v-model="item.check_standard"
            :value="check_standard.check_standard"
            input-align="center"
            placeholder="请填写指标"
            @change="changeCheck(item)"
            right-icon="delete-o"
            @click-right-icon="delzb(item.check_item_id)"
          />
        </van-cell-group>
      </div>
      <van-button
        class=""
        round
        block
        size="small"
        type="primary"
        @click="preservZb"
        v-track="{
          triggerType: 'click',
          title: '增删指标',
          currentUrl: $route.path,
          behavior: this._data,
          duration: new Date().getTime(),
        }"
        style="position: absolute; bottom: 5%"
        >保 存</van-button
      >
    </van-popup>
    <!--剂型下拉  -->
    <van-popup v-model="showJx" round position="bottom">
      <van-picker
        show-toolbar
        title="选择剂型"
        :columns="jxList"
        value-key="jx_name"
        @cancel="showJx = false"
        @confirm="jxConfirm"
      />
    </van-popup>
    <!-- 供应商列表 -->
    <van-action-sheet
      v-model="supplierShow"
      title="供应商"
      class="elasticlayer"
      :overlay="false"
      style="height: 60%"
    >
      <van-search
        v-model="supplierValue"
        placeholder="请输入搜索关键词"
        @input="searchSupplier"
      />
      <van-action-sheet
        v-model="supplierShow"
        :actions="supplierOption"
        @select="supplierSelect"
        :overlay="false"
        style="height: 45%"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import Vue from "vue";
import XLSX from "xlsx";
import {
  List,
  Cell,
  CellGroup,
  Tag,
  Col,
  Row,
  Button,
  Popup,
  Field,
  Toast,
  Dialog,
  Icon,
  Search,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  Uploader,
  NavBar,
  Picker,
  Switch,
  Notify,
} from "vant";
import {
  ossUpload,
  ListOss,
  showPrivateOss,
  deletePrivateOss,
} from "@/common/fileUpload";
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(Uploader);
Vue.use(NavBar);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Notify);

export default {
  name: "Product",
  components: {},

  data() {
    return {
      count: 0,
      page: 1,
      offset: 10,
      searchValue: "",
      productList: [],
      searchLoading: false,
      searchfinished: false,
      loading: false,
      finished: false,
      addShow: false,
      toEditshow: false,
      upShow: false, //指标下拉
      showJx: false,
      option1: [], //下拉列表
      value1: "",
      layerTitle: "",
      editId: "", //编辑id
      erpCode: "", //erp编码
      sampleCode: "", //产品编码
      sampleName: "", //产品名称
      sampleSpecs: "", //产品规格
      sampleType: "", //产品批次
      jxName: "", //剂型名称
      detectionIndex: "",
      productDate: "", //生产日期
      mark: "", //备注
      checkShow: false, //检测弹窗
      checkList: [],
      zbParams: [],
      zbList: {},
      fileList: [],
      checkArr: [],
      check_standard: "",
      upload: [],
      zbValue: "", //指标
      jxList: [], // 剂型列表
      sample_jx: "",
      supplier: "", //供应商
      supplierCode: "",
      supplierValue: "",
      supplierShow: false,
      supplierOption: [],
      checked: false, //禁用标志
    };
  },
  created: function () {
    this.getjxList();
  },
  mounted() {
    this.$get("check-item/get-check-item-list", "api", {
      page: 1,
      offset: 1000,
      item_name: "",
    }).then(
      (res) => {
        this.checkArr = res.data.list;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  methods: {
    onLoad() {
      var params = {
        page: this.page++,
        offset: this.offset,
        sample_name: this.searchValue,
      };
      this.$get("sample/get-sample-list", "api", params)
        .then((res) => {
          var data = res.data.list;
          var length1 = res.data.list.length;
          var length2 = res.data.count;
          this.count = res.data.count;
          this.loading = false;
          this.productList = this.productList.concat(data); //追加数据
          if (length1 >= length2) {
            //数据全部加载完成
            this.finished = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.finished = true;
        });
    },
    // 搜索
    inputValue(value) {
      this.searchValue = value;
      this.productList = [];
      this.finished = false;
      this.loading = true;
      this.page = 1;
      this.onLoad();
    },
    // 点击 x
    clearValue() {
      this.productList = [];
      this.finished = false;
      this.loading = true;
      this.page = 1;
      this.onLoad();
    },
    // 检测指标
    detection(item) {
      var that = this;
      that.zbList = item;
      this.sampleName = item.sample_name;
      this.checkShow = true;
      this.upShow = false;
      this.$post("sample/get-sample-check-standard", "api", {
        sample_code: item.sample_code,
      }).then(
        (res) => {
          console.log(res);
          that.zbParams = res.data;
          var data = res.data.sample_check_standard;
          that.checkList = data;
        },
        (err) => {
          console.log(err);
          Toast.fail(err.message);
        }
      );
    },
    // 选择供应商
    getSupplier() {
      this.searchSupplier("");
    },
    // 搜索供应商
    searchSupplier(value) {
      this.supplierShow = true;
      var params = {
        page: 1,
        offset: 100,
        supplier_name: value,
      };
      this.$get("supplier/get-supplier-list", "api", params).then(
        (res) => {
          var data = res.data.list;
          data.forEach((ele) => {
            ele.name = ele.supplier_name;
          });
          this.supplierOption = data;
        },
        (err) => {
          // console.log(err)
        }
      );
    },
    // 选择供应商
    supplierSelect(item) {
      this.supplier = item.supplier_name;
      this.supplierShow = false;
    },
    // 编辑
    toEdit(id) {
      var that = this;
      this.layerTitle = "编辑产品";
      that.editId = id;
      var jxList = this.jxList;
      that.$post("sample/get-sample-detail", "api", { id: id }).then(
        (res) => {
          var data = res.data;
          jxList.forEach((ref) => {
            if (data.sample_jx == ref.id) {
              data.sample_jx_name = ref.jx_name;
            }
          });
          if (data.supplier !== null) {
            that.supplier = data.supplier.supplier_name;
            that.supplierCode = data.supplier.supplier_code;
          }
          that.jxName = data.sample_jx_name;
          (that.erpCode = data.sample_erp_code),
            (that.sampleCode = data.sample_code),
            (that.sampleName = data.sample_name),
            (that.sampleSpecs = data.sample_specs),
            (that.sampleType = data.sample_type),
            (that.mark = data.mark);
        },
        (err) => {
          console.log(err);
          Notify({ type: "warning", message: err.message });
        }
      );
      this.toEditshow = true;
    },
    // 编辑保存
    preservEdit() {
      var that = this;
      this.toEditshow = false;
      var params = {
        id: this.editId,
        sample_name: this.sampleName,
        sample_specs: this.sampleSpecs,
        sample_erp_code: this.erpCode,
        sample_jx: this.sample_jx,
        supplier_code: this.supplierCode,
      };
      this.$post("sample/save-sample", "api", params).then(
        (res) => {
          // Toast.success(res.message);
          Nnotify({ type: "success", message: res.message });
          this.finished = false;
          this.loading = true;
          that.page = 1;
          that.productList = [];
          that.onLoad();
        },
        (err) => {
          console.log(err);
          // Toast.fail(err.message);
          Notify({ type: "warning", message: err.message });
        }
      );
    },
    // 删除
    delBtn(id, name) {
      var that = this;
      Dialog.confirm({
        title: name,
        message: "确定要删除此产品",
      })
        .then(() => {
          that.$post("sample/delete-sample", "api", { id: id }).then(
            (res) => {
              // Toast.success(res.message);
              Notify({ type: "success", message: res.message });
              that.page = 1;
              that.productList = [];
              this.finished = false;
              this.loading = true;
              that.onLoad();
            },
            (err) => {
              console.log(err);
              // Toast.fail(err.message);
              Notify({ type: "warning", message: err.message });
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    // 新增指标 点击 +
    addzb() {
      this.searchZb("");
      this.zbValue = "";
    },
    searchZb(value) {
      this.upShow = true;
      var newList = [];
      var params = {
        page: 1,
        offset: 10000,
        item_name: value,
      };
      this.$get("check-item/get-check-item-list", "api", params).then(
        (res) => {
          var checkList = this.checkList;
          var data = res.data.list;
          data.forEach((ele) => {
            ele.name = ele.item_name;
          });
          for (var i = 0; i < data.length; i++) {
            var flag = false;
            for (var j = 0; j < checkList.length; j++) {
              if (checkList[j].check_item_id == data[i].id) {
                flag = true;
              }
            }
            if (!flag) {
              newList.push(data[i]);
            }
          }
          this.option1 = newList;
        },
        (err) => {
          // console.log(err)
        }
      );
    },
    // 选择下拉
    onSelect(item) {
      item.check_item_id = item.id;
      item.check_name = item.item_name;
      item.check_standard = "";
      this.upShow = false;
      this.checkList.push(item);
    },
    changeCheck(item) {
      this.check_standard = item;
      this.checkList.forEach((ele, index) => {
        if (ele.id == item.check_item_id) {
          console.log(index);
          this.$set(this.checkList, index, item);
        }
      });
    },
    // 保存指标
    preservZb() {
      var that = this;
      var flag = false;
      var zbList = this.zbList;
      var checkList = this.checkList;
      checkList.forEach((ele) => {
        ele.check_standard = ele.check_standard.trim();
      });
      var params = {
        sample_code: zbList.sample_code,
        sample_name: zbList.sample_name,
        sample_specs: zbList.sample_specs,
        sample_erp_code: zbList.sample_erp_code,
        sample_check_standard: checkList,
      };
      var scList = params.sample_check_standard;
      if (scList.length == 0) {
        flag = true;
      }
      for (var i = 0; i < scList.length; i++) {
        if (scList[i].check_standard == "") {
          flag = false;
          Notify({
            type: "warning",
            message: scList[i].check_name + "" + "检测指标不能为空",
          });
          break;
        } else {
          flag = true;
        }
      }
      if (flag == true) {
        this.$post("sample/save-sample-check-standard", "api", params).then(
          (res) => {
            Notify({ type: "success", message: res.message });
            that.checkShow = false;
            // Toast.success(res.message);
          },
          (err) => {
            Notify({ type: "warning", message: err.message });
          }
        );
      }
    },
    //  删除指标
    delzb(id) {
      var checkList = this.checkList;
      checkList.forEach((ele, index) => {
        if (id == ele.check_item_id) {
          checkList.splice(index, 1);
        }
      });
    },
    // 上传文件
    afterRead(file) {
      var that = this;
      this.file2JSON(file)
        .then((data) => {
          var name = file.file.name;
          var checkArr = that.checkArr; //检测标准
          var sheetName = data[0].sheetName;
          var data = data[0].sheet;
          var list = [];
          data.forEach((ele, index) => {
            var entries = Object.entries(ele);
            entries.forEach((ref, index, arr) => {
              arr[index] = {
                check_name: ref[0].toString().trim(),
                check_standard: ref[1].toString().trim(),
                check_item_id: "",
              };
            });
            entries.splice(0, 5);
            checkArr.forEach((chele) => {
              entries.forEach((enele) => {
                if (chele.item_name == enele.check_name) {
                  enele.check_item_id = chele.id;
                }
              });
            });
            list.push({
              sample_erp_code: ele["ERP编码"],
              sample_name: ele["样品名称"],
              sample_specs: ele["样品规格"],
              sample_ref_standard: ele["标准"],
              supplier_name: ele["供应商"],
              sample_jx: sheetName,
              sample_check_standard: entries,
            });
          });
          list.forEach((ele) => {
            ele.check_list = [];
            ele.sample_check_standard.forEach((stanEle, index) => {
              if (stanEle.check_name.indexOf("__EMPTY") != -1) {
                return;
              } else {
                ele.check_list.push(stanEle);
              }
            });
            ele.sample_check_standard = ele.check_list;
            delete ele.check_list;
          });
          var index = name.lastIndexOf(".");
          var params = {};
          ossUpload(file)
            .then((res) => {
              if (res.url) {
                params = {
                  attach_name: res.name,
                  attach_type: name.substring(index + 1, name.length),
                  attach_path: res.path,
                  attach_url: res.url,
                  list: list,
                };
                // console.log(params)
                that
                  .$post(
                    "inspect-standard/import-sample-check-standard",
                    "api",
                    params
                  )
                  .then(
                    (res) => {
                      // Toast.success(res.message);
                      Notify({ type: "success", message: res.message });
                      file.status = "done";
                      file.message = "上传成功";
                      that.page = 1;
                      that.productList = [];
                      that.finished = false;
                      that.loading = true;
                      that.onLoad();
                    },
                    (err) => {
                      Notify({ type: "warning", message: err.message });
                      file.status = "failed";
                      file.message = "上传失败";
                      console.log(err);
                    }
                  );
              } else {
                // Toast.fail(err.message);
                Notify({ type: "success", message: err.message });
                file.status = "failed";
                file.message = "上传失败";
              }
            })
            .catch((err) => {
              // Toast.fail(err.message);
              Notify({ type: "success", message: err.message });
              console.log(err);
            });
        })
        .catch((err) => {
          // Toast.fail(err.message);
          Notify({ type: "success", message: err.message });
        });
    },
    file2JSON(file) {
      var XLSX = require("xlsx");
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName], {
                head: 0,
                defval: "",
              }),
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.file);
      });
    },
    // 获取剂型列表
    getjxList() {
      var params = {
        page: 1,
        offset: 1000,
        jx_name: "",
      };
      this.$post("sample-jx/get-sample-jx-list", "api", params).then(
        (res) => {
          var data = res.data.list;
          this.jxList = data;
        },
        (err) => {
          // console.log(err)
          this.jxList = [];
          Notify({ type: "warning", message: err.message });
        }
      );
    },
    // 点击选择剂型
    jxConfirm(val) {
      this.jxName = val.jx_name;
      this.showJx = false;
      this.sample_jx = val.id;
    },
    // 返回
    toHome() {
      this.$router.push({
        path: "/home",
      });
    },

    // 启用禁用
    changSwitch(sampleStatus, id) {
      var that = this;
      if (sampleStatus == "1") {
        Dialog.confirm({
          message: "确定要禁用此产品",
        })
          .then(() => {
            that
              .$post("sample/able-sample", "api", {
                id: id,
                sample_status: sampleStatus,
              })
              .then(
                (res) => {
                  // Toast.success(res.message);
                  // that.$notify({ type: "success", message: res.message });
                  Notify({ type: "success", message: res.message });
                  that.page = 1;
                  that.productList = [];
                  this.finished = false;
                  this.loading = true;
                  that.onLoad();
                },
                (err) => {
                  console.log(err);
                  // Toast.fail(err.message);
                  // that.$notify({ type: "warning", message: err.message });
                  Notify({ type: "warning", message: err.message });
                  that.page = 1;
                  that.productList = [];
                  this.finished = false;
                  this.loading = true;
                  that.onLoad();
                }
              );
          })
          .catch(() => {
            that.page = 1;
            that.productList = [];
            this.finished = false;
            this.loading = true;
            that.onLoad();
            // on cancel
          });
      } else {
        Dialog.confirm({
          message: "确定要启用此产品",
        })
          .then(() => {
            that
              .$post("sample/able-sample", "api", {
                id: id,
                sample_status: sampleStatus,
              })
              .then(
                (res) => {
                  // Toast.success(res.message);
                  // that.$notify({ type: "success", message: res.message });
                  Notify({ type: "success", message: res.message });
                  that.page = 1;
                  that.productList = [];
                  this.finished = false;
                  this.loading = true;
                  that.onLoad();
                },
                (err) => {
                  console.log(err);
                  // Toast.fail(err.message);
                  // that.$notify({ type: "warning", message: err.message });
                  Notify({ type: "warning", message: err.message });
                  that.page = 1;
                  that.productList = [];
                  this.finished = false;
                  this.loading = true;
                  that.onLoad();
                }
              );
          })
          .catch(() => {
            that.page = 1;
            that.productList = [];
            this.finished = false;
            this.loading = true;
            that.onLoad();
            // on cancel
          });
      }
    },
  },
};
</script>
<style scoped>
.status {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 42px;
  z-index: 0;
  opacity: 40%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}
.border-tb {
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
}
.elasticlayer {
  height: 60%;
}
.tac {
  text-align: center;
}
.add-one {
  width: 50px;
  height: 50px;
  background: wheat;
  position: fixed;
  bottom: 100px;
  z-index: 99999;
  border-radius: 50%;
  text-align: center;
  opacity: 0.8;
  right: 0;
  cursor: pointer;
}
.red {
  color: #ff4b38;
}
.van-cell-group--inset {
  height: 300px;
  overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  /* position: fixed; */
  /* top: 46px; */
  width: -webkit-fill-available;
  z-index: 99;
}
.import-excel {
  width: 48px;
  height: 48px;
  background: #3d7cf3;
  position: fixed;
  bottom: 150px;
  z-index: 999;
  border-radius: 50%;
  text-align: center;
  opacity: 0.8;
  right: 10px;
  cursor: pointer;
}
.add {
  margin-left: 10px;
  margin-bottom: 15px;
}
.selectAd :deep(.van-icon-delete-o:before) {
  color: #ff4b38;
}
</style>