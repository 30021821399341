// 上传oss
import common from '@/common/common.js'
import { get, post } from '@/api/https'
export function ossUpload(fileBuffer) {
  return new Promise((resolve, reject) => {
    const files = fileBuffer.file
    let formData = new FormData()
    // const foldName = common.formatDate((new Date()).valueOf() * 1000)
    const type = files.name.slice(files.name.lastIndexOf('.') + 1)
    const uid = files.lastModified + Math.floor(Math.random()*100)
    formData.append('files', files)
    formData.append('path', 'upload/' + uid + '.' + type)
    post('user/upload-oss', 'api', formData, {
      transformRequest: [
        function(data, headers) {
          // 去除post请求默认的Content-Type
          delete headers['Content-Type']
          return data
        },
      ],
    }).then(res => {
      if (+res.code === 0) {
        const resultData = {
          uid: uid,
          name: formData.get('path'),
          url: res.data.signedUrl,
          type: files.type
        }
        resolve(resultData)
        return resultData
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取oss地址
export function showPrivateOss (picName, typeName) {
  if (typeName === 'oss') {
    return { code: 200, url: picName }
  } else {
    get('user/get-signed-url', 'api', {name: picName}).then(res => {
      if (+res.code === 0) {
        return { code: 200, url: res.data.signedUrl}
      }
    })
  }
}